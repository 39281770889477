import { useSuspenseQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { axiosConfigurationAtom } from '@/atoms/axiosConfiguration';
import { useSelfCompany } from '@/hooks//useSelfCompany';
import { createIndexUsersModel } from '@/models/indexUser';
import { UsersApi } from '@/types/typescript-axios';

export const queryKey = 'indexUsers';

export const useIndexUsers = () => {
  const axiosConfiguration = useAtomValue(axiosConfigurationAtom);

  const { selfCompany } = useSelfCompany();

  const { data: indexUsers } = useSuspenseQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const api = new UsersApi(axiosConfiguration);

      // SystemAdminは空の配列を返す
      if (selfCompany) {
        const { data } = await api.indexUsers();
        return createIndexUsersModel(data, selfCompany);
      } else {
        return [];
      }
    },
  });

  return { indexUsers };
};
