import { CompanyModel } from '@/models/company';
import { IndexUsersResponse, IndexUsersResponseUsers } from '@/types/typescript-axios';
import { getAuthorityName } from '@/utils/AuthorityName';
import { createDepartmentsInfo, DepartmentInfo } from '@/utils/DepartmentsInfo';

export type IndexUserModel = {
  id: number;
  name: string;
  lastName: string;
  firstName: string;
  email: string;
  authority: IndexUsersResponseUsers['authority'];
  authorityName: string;
  sectionIds: number[] | null;
  departmentUniqueKeys: string[] | null;
  positionUniqueKey: string | null;
  sectionNames: string[] | null;
  departmentNames: string[] | null;
  positionName: string | null;
  departmentsInfo: DepartmentInfo[];
  isManager: boolean;
};

export type IndexUsersModel = IndexUserModel[];

export const createIndexUserModel = (data: IndexUsersResponseUsers, company: CompanyModel | null): IndexUserModel => {
  const getDepartmentNamesArray = (departmentKeys: string[] | null) => {
    if (departmentKeys == null || company == null) return null;

    const filteredDepartments = company.departments.filter(department => departmentKeys.includes(department.uniqueKey));
    const fullPathNames: string[] = [];
    filteredDepartments.forEach(department => {
      fullPathNames.push(
        department.fullPathName.replace(/(^\/)|(\/$)|\//g, match => {
          return match === '/' ? ' ' : '';
        }),
      );
    });
    return fullPathNames;
  };

  const getSectionNamesArray = (sectionIds: number[] | null) => {
    if (sectionIds == null || company == null) return null;

    const filteredSections = company.sections.filter(section => sectionIds.includes(section.id));
    const fullPathNames: string[] = [];
    filteredSections.forEach(section => {
      fullPathNames.push(section.pathItems.map(item => item.name).join(' '));
    });
    return fullPathNames;
  };

  const getPositionName = (positionKey: string | null) => {
    if (positionKey == null || company == null) return null;

    const filteredPosition = company.positions.find(position => position.uniqueKey === positionKey);
    if (filteredPosition) return filteredPosition.name;
    return null;
  };

  return {
    id: data.id,
    name: `${data.last_name} ${data.first_name}`,
    lastName: data.last_name,
    firstName: data.first_name,
    email: data.email,
    authority: data.authority,
    authorityName: getAuthorityName(data.authority),
    sectionIds: data.section_ids,
    departmentUniqueKeys: data.department_unique_keys,
    positionUniqueKey: data.position_unique_key,
    sectionNames: getSectionNamesArray(data.section_ids),
    departmentNames: getDepartmentNamesArray(data.department_unique_keys),
    positionName: getPositionName(data.position_unique_key),
    departmentsInfo: createDepartmentsInfo(company?.departments, data.department_unique_keys),
    isManager: company?.positions.find(position => position.uniqueKey === data.position_unique_key)?.isManager || false,
  };
};

export const createIndexUsersModel = (data: IndexUsersResponse, company: CompanyModel | null): IndexUsersModel => {
  return data.users.map(item => createIndexUserModel(item, company));
};
