export const getAuthorityName = (authority: string) => {
  switch (authority) {
    case 'Admin':
      return '管理者';
    case 'Viewer':
      return 'メンター';
    case 'Guest':
      return 'メンティ';
    default:
      return '';
  }
};
