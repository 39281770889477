import { ReactNode, useEffect, useState } from 'react';

import { TermModal } from '@/components/utils/Auth/TermContainer/TermModal';
import { useSelf } from '@/hooks/useSelf';

type Props = {
  children: ReactNode;
};

export const TermContainer = ({ children }: Props) => {
  const { self } = useSelf();

  const [isOpenTerm, setIsOpenTerm] = useState<boolean>();

  useEffect(() => {
    if (self?.user) {
      if (!self.user.isAgreement) {
        setIsOpenTerm(true);
      } else {
        setIsOpenTerm(false);
      }
    } else if (self?.admin) {
      setIsOpenTerm(false);
    }
  }, [self?.user, self?.admin]);

  if (isOpenTerm == null) return null;

  if (isOpenTerm) {
    return <TermModal setIsOpen={setIsOpenTerm} />;
  }

  return <>{children}</>;
};
