import { useCallback } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { axiosConfigurationAtom } from '@/atoms/axiosConfiguration';
import { createSelfModel } from '@/models/self';
import { SelfApi } from '@/types/typescript-axios';

export const queryKey = 'self';

export const useSelf = () => {
  const axiosConfiguration = useAtomValue(axiosConfigurationAtom);

  const { data: self } = useSuspenseQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const api = new SelfApi(axiosConfiguration);

      const { data } = await api.indexSelf();

      return createSelfModel(data);
    },
  });

  return { self };
};

export const useRefetchSelf = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [queryKey] });
  }, [queryClient]);
};
