import { IndexCompaniesResponseSections } from '@/types/typescript-axios';

type SectionPathItem = {
  id: number;
  name: string;
  path: string | null;
};

export type Section = {
  id: number;
  name: string;
  path: string | null;
  pathItems: SectionPathItem[];
};

export const createSectionsModel = (sections: IndexCompaniesResponseSections[]): Section[] => {
  return sections.map(section => {
    return {
      id: section.id,
      name: section.name,
      path: section.path || null,
      pathItems: section.path_items.map(item => {
        return {
          id: item.id,
          name: item.name,
          path: item.path || null,
        };
      }),
    };
  });
};
