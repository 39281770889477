import { IndexSelfResponseAdmin } from '@/types/typescript-axios';

export type SelfAdminModel = {
  id: number;
  name: string;
};

export const createSelfAdminModel = (data: IndexSelfResponseAdmin): SelfAdminModel => ({
  id: data.id,
  name: data.name,
});
