import { useEffect } from 'react';

import { useAtom } from 'jotai';
import { useRouter } from 'next/router';

import { isSystemAdminPageAtom } from '@/atoms/isSystemAdminPage';

export const useIsSystemAdminPage = () => {
  const router = useRouter();

  const [isSystemAdminPage, setIsSystemAdminPage] = useAtom(isSystemAdminPageAtom);

  useEffect(() => {
    setIsSystemAdminPage(RegExp(/^\/admin/).test(router.pathname));
  }, [router, setIsSystemAdminPage]);

  return { isSystemAdminPage, setIsSystemAdminPage };
};
