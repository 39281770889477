import { createSelfAdminModel, SelfAdminModel } from '@/models/self/selfAdmin';
import { createSelfRoomModel, SelfRoomModel } from '@/models/self/selfRoom';
import { createSelfUserModel, SelfUserModel } from '@/models/self/selfUser';
import { IndexSelfResponse } from '@/types/typescript-axios';

export type SelfModel = {
  admin: SelfAdminModel | null;
  user: SelfUserModel | null;
  room: SelfRoomModel | null;
};

export const createSelfModel = (data: IndexSelfResponse): SelfModel => ({
  admin: data.admin ? createSelfAdminModel(data.admin) : null,
  user: data.user ? createSelfUserModel(data.user) : null,
  room: data.room ? createSelfRoomModel(data.room) : null,
});
