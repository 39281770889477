import { useEffect } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { useIsSystemAdminPage } from '@/hooks/useIsSystemAdminPage';
import { useSelectedAdminCompanyId } from '@/hooks/useSelectedAdminCompanyId';
import { useSelectedAdminUserId } from '@/hooks/useSelectedAdminUserId';
import { useSelfCompany } from '@/hooks/useSelfCompany';
import { useSelfUser } from '@/hooks/useSelfUser';

export const Initialization = () => {
  const { selfUser } = useSelfUser();
  const { selfCompany } = useSelfCompany();

  useIsSystemAdminPage();
  useSelectedAdminCompanyId();
  useSelectedAdminUserId();

  useEffect(() => {
    datadogRum.setUser({
      id: selfUser?.accountId.toString(),
      reviiUserId: selfUser?.id,
      companyId: selfCompany?.id,
      authority: selfUser?.authority,
      liveClientId: selfUser?.liveClientId,
    });
    datadogLogs.setGlobalContext({
      accountId: selfUser?.accountId.toString(),
      reviiUserId: selfUser?.id,
      companyId: selfCompany?.id,
      authority: selfUser?.authority,
      liveClientId: selfUser?.liveClientId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
