import { ReactNode, useEffect } from 'react';

import { useRouter } from 'next/router';

import { useSelf } from '@/hooks/useSelf';
import { pagesPath } from '@/utils/$path';

type Props = {
  children: ReactNode;
};

export const PageRedirector = ({ children }: Props) => {
  const router = useRouter();
  const { self } = useSelf();

  useEffect(() => {
    const regexSystemAdmin = new RegExp('^(?!/admin).*$');
    const regexCompanyUser = new RegExp('^(/admin).*$');

    if (!self?.user && self?.admin && regexSystemAdmin.test(router.pathname)) {
      router.replace(pagesPath.admin.companies.$url());
    }
    if (self?.user && !self?.admin && regexCompanyUser.test(router.pathname)) {
      router.replace(pagesPath.home.$url());
    }
  }, [router, self?.admin, self?.user]);

  return <>{children}</>;
};
