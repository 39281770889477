import { IndexCompaniesResponsePositions } from '@/types/typescript-axios';

export type Position = {
  uniqueKey: string;
  name: string;
  isManager: boolean;
};

export const createPositionsModel = (positions: IndexCompaniesResponsePositions[]): Position[] => {
  return positions.map(position => {
    return {
      uniqueKey: position.unique_key,
      name: position.name,
      isManager: position.is_manager,
    };
  });
};
