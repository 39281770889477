import { IndexSelfResponseUser } from '@/types/typescript-axios';

export type SelfUserModel = {
  id: number;
  accountId: number;
  liveClientId: string;
  isAgreement: boolean;
};

export const createSelfUserModel = (data: IndexSelfResponseUser): SelfUserModel => ({
  id: data.id,
  accountId: data.account_id,
  liveClientId: data.live_client_id,
  isAgreement: data.is_agreement ?? false,
});
