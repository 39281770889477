import { IndexCompaniesResponseDepartments } from '@/types/typescript-axios';

export type Department = {
  uniqueKey: string;
  fullPathName: string;
  code: number | null;
};

export const createDepartmentsModel = (departments: IndexCompaniesResponseDepartments[]): Department[] => {
  return departments.map(department => {
    return {
      uniqueKey: department.unique_key,
      fullPathName: department.full_path_name,
      code: department.code || null,
    };
  });
};
