import { ReactNode, Suspense } from 'react';

import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { signOut } from 'next-auth/react';

import { Loading } from '@/components/shared/Loading';
import { Initialization } from '@/components/utils/Auth/Initialization';
import { LoginRestrictedRoute } from '@/components/utils/Auth/LoginRestrictedRoute';
import { PageRedirector } from '@/components/utils/Auth/PageRedirector';
import { TermContainer } from '@/components/utils/Auth/TermContainer';
import { BaseErrorBoundary } from '@/components/utils/BaseErrorBoundary';
import { ApiErrorCode, LoginErrorType } from '@/constants/General';
import { pagesPath } from '@/utils/$path';

type Props = {
  children: ReactNode;
};

export const Auth = ({ children }: Props) => {
  const router = useRouter();

  return (
    <LoginRestrictedRoute>
      <BaseErrorBoundary
        fallbackRender={() => null}
        onError={error => {
          if (error instanceof AxiosError) {
            const errorCode = error.response?.data.code;

            if (error.message.includes(LoginErrorType.network)) {
              // FIXME: Safariでログイン直後にネットワークエラーが発生する場合があるため応急処置
              // https://developer.apple.com/forums/thread/96501
              router.replace(pagesPath.home.$url());
              return;
            }

            switch (errorCode) {
              case ApiErrorCode.UNVERIFIED_ORGANIZATION:
                router.replace(pagesPath.errors.invalidOrganizationLogin.$url());
                break;
              case ApiErrorCode.DENY_IP_ADDRESS:
                router.replace(pagesPath.errors.denyIp.$url());
                break;
              case ApiErrorCode.PASSWORD_EXPIRATION:
                router.replace(pagesPath.errors.passwordExpiration.$url());
                break;
              case ApiErrorCode.NOT_AUTHENTICATED:
                signOut({ callbackUrl: 'signOutAndRedirect' });
                break;
              default:
                router.replace(pagesPath.errors.login.$url());
            }
          }
        }}
      >
        <Suspense fallback={<Loading loading size="large" />}>
          <Initialization />
          <PageRedirector>
            <TermContainer>{children}</TermContainer>
          </PageRedirector>
        </Suspense>
      </BaseErrorBoundary>
    </LoginRestrictedRoute>
  );
};
