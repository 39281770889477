import { useMemo } from 'react';

import { useIndexUsers } from '@/hooks/useIndexUsers';
import { useSelf } from '@/hooks/useSelf';
import { useSelfCompany } from '@/hooks/useSelfCompany';
import { createDepartmentsInfo } from '@/utils/DepartmentsInfo';

export const useSelfUser = () => {
  const { self } = useSelf();
  const { indexUsers } = useIndexUsers();
  const { selfCompany } = useSelfCompany();

  const selfUser = useMemo(() => {
    if (!self.user) return null;

    const findUser = indexUsers?.find(user => user.id === self.user?.id);

    if (!findUser) return null;

    return {
      id: findUser?.id,
      accountId: self.user.accountId,
      name: `${findUser?.lastName} ${findUser?.firstName}`,
      email: findUser?.email,
      authority: findUser?.authority,
      isAgreement: self.user.isAgreement,
      liveClientId: self.user?.liveClientId,
      departmentsInfo: createDepartmentsInfo(selfCompany?.departments, findUser.departmentUniqueKeys),
      positionName: findUser?.positionName,
      isManager:
        selfCompany?.positions.find(position => position.uniqueKey === findUser.positionUniqueKey)?.isManager || false,
    };
  }, [indexUsers, self.user, selfCompany]);

  return { selfUser };
};
