import { useMemo } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { axiosConfigurationAtom } from '@/atoms/axiosConfiguration';
import { useSnackbarContext } from '@/components/layouts/Snackbar';
import { queryKey } from '@/hooks/useSelf';
import { SelfApi } from '@/types/typescript-axios';

export const usePostAgreement = () => {
  const queryClient = useQueryClient();

  const { addSnackbar } = useSnackbarContext();
  const axiosConfiguration = useAtomValue(axiosConfigurationAtom);

  const api = useMemo(() => new SelfApi(axiosConfiguration), [axiosConfiguration]);

  return useMutation({
    mutationFn: async () => await api.postAgreement(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey] });
    },
    onError: () => {
      addSnackbar({
        theme: 'danger',
        content: 'エラーが発生しました。しばらくしてから再度お試しください。',
        autoHide: true,
      });
    },
  });
};
