import { useCallback } from 'react';

import { Updater, useQueryClient } from '@tanstack/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { axiosConfigurationAtom } from '@/atoms/axiosConfiguration';
import { useSelf } from '@/hooks/useSelf';
import { CompanyModel } from '@/models/company';
import { createCompanyModel } from '@/models/company';
import { CompanyApi } from '@/types/typescript-axios';

export const queryKey = 'selfCompany';

export const useSelfCompany = () => {
  const axiosConfiguration = useAtomValue(axiosConfigurationAtom);

  const queryClient = useQueryClient();
  const { self } = useSelf();

  const { data: selfCompany } = useSuspenseQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const api = new CompanyApi(axiosConfiguration);

      // SystemAdminはnullを返す
      if (self.user) {
        const { data } = await api.indexCompanies();
        return createCompanyModel(data);
      } else {
        return null;
      }
    },
  });

  const refetch = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [queryKey] });
  }, [queryClient]);

  const update = useCallback(
    (updater: Updater<CompanyModel | undefined, CompanyModel | undefined>) => {
      queryClient.setQueryData<CompanyModel>([queryKey], updater);
    },
    [queryClient],
  );

  return { selfCompany, update, refetch };
};
