import { CompanyReportSetting } from '@/types/typescript-axios';

export type CompanyReportSettingsModel = {
  isGuestReportEnabled: boolean;
  isGuestMovieEnabled: boolean;
  isGuestWordcloudEnabled: boolean;
  isGuestLiveTranscriptionEnabled: boolean;
  isOwnerMovieEnabled: boolean;
  isOwnerWordcloudEnabled: boolean;
  isOwnerLiveTranscriptionEnabled: boolean;
};

export const createCompanyReportSettingsModel = (data: CompanyReportSetting): CompanyReportSettingsModel => {
  return {
    isGuestReportEnabled: data.is_guest_report_enabled,
    isGuestMovieEnabled: data.is_guest_movie_enabled,
    isGuestWordcloudEnabled: data.is_guest_wordcloud_enabled,
    isGuestLiveTranscriptionEnabled: data.is_guest_live_transcription_enabled,
    isOwnerMovieEnabled: data.is_owner_movie_enabled,
    isOwnerWordcloudEnabled: data.is_owner_wordcloud_enabled,
    isOwnerLiveTranscriptionEnabled: data.is_owner_live_transcription_enabled,
  };
};
