import { useEffect } from 'react';

import { useAtom } from 'jotai';
import { useRouter } from 'next/router';

import { selectedAdminUserIdAtom } from '@/atoms/selectedAdminUserId';

export const useSelectedAdminUserId = () => {
  const router = useRouter();

  const [selectedAdminUserId, setSelectedAdminUserId] = useAtom(selectedAdminUserIdAtom);

  useEffect(() => {
    const { userId } = router.query;
    setSelectedAdminUserId(userId ? Number(userId) : undefined);
  }, [router, setSelectedAdminUserId]);

  return { selectedAdminUserId, setSelectedAdminUserId };
};
