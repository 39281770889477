import { Department } from '@/models/company/departments';

export type DepartmentInfo = Department & {
  directDepartmentName: string;
};

export const createDepartmentsInfo = (departments: Department[] | undefined, departmentUniqueKeys: string[] | null) => {
  const departmentsInfo: DepartmentInfo[] = [];

  departments?.forEach(department => {
    if (departmentUniqueKeys?.includes(department.uniqueKey)) {
      const names = department.fullPathName.split('/').filter(Boolean);
      departmentsInfo.push({
        ...department,
        directDepartmentName: names[names.length - 1],
      });
    }
  });
  return departmentsInfo;
};
