import { PropsWithChildren, useEffect } from 'react';

import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useSession, signIn } from 'next-auth/react';

import { axiosConfigurationAtom } from '@/atoms/axiosConfiguration';
import { commonApi } from '@/config/Variant';
import { Configuration } from '@/types/typescript-axios';
import { pagesPath } from '@/utils/$path';

export const LoginRestrictedRoute = ({ children }: PropsWithChildren) => {
  const router = useRouter();
  const { data: session, status, update } = useSession();

  const [axiosConfiguration, setAxiosConfiguration] = useAtom(axiosConfigurationAtom);

  // アクセストークンの更新
  useEffect(() => {
    // 未認証であればログイン画面に遷移する
    if (status === 'unauthenticated') {
      const organizationIdentifier = localStorage.getItem('organizationIdentifier');

      // callbackUrl が指定されていればその画面に遷移する。それ以外は home 画面に遷移する
      const callbackUrl = (router.query.callbackUrl as string | undefined) ?? '/home';

      // local storage に保存されている identifier があればオーガナイザーログイン画面に遷移する
      if (organizationIdentifier) {
        router.push(pagesPath.organization_login.$url());
      } else {
        signIn('auth0', { callbackUrl });
      }
      // 認証済みであればアクセストークンを更新する
    } else if (status === 'authenticated') {
      const axiosConfiguration = new Configuration({
        basePath: commonApi,
        accessToken: session?.accessToken,
      });

      setAxiosConfiguration(axiosConfiguration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.accessToken, status]);

  // 1時間ごとにアクセストークンを更新する
  // ポーリングの間隔は Auth0 の設定に依存する
  useEffect(() => {
    const interval = setInterval(() => update(), 1000 * 60 * 60);
    return () => clearInterval(interval);
  }, [update]);

  if (!axiosConfiguration.accessToken) {
    return null;
  }

  return <>{children}</>;
};
