export const General = {
  Success: 'success',
  Failure: 'failure',
  VideoCallSenderType: '2',
  Skipped: 'skipped',
  Loading: 'loading',
  NotStarted: 'notStarted',
  ResultTypeNotYet: 0,
  ResultTypeSuccess: 1,
  ResultTypeFailure: 2,
  TestTypeFullCheck: 0,
  TestTypeInstantCheck: 1,
  surveyType: {
    beforeMentee: 'before_mentee',
    beforeMenter: 'before_menter',
    afterMentee: 'after_mentee',
    afterMenter: 'after_menter',
  },
} as const;

export const Authority = {
  Guest: 'member',
  Viewer: 'manager',
  Admin: 'admin',
} as const;

export const axesAverage: number[] = [50, 50, 50, 50, 50, 50];

export const faqURL = 'https://knowledge.revii.jp/304c1c7da75b4268b88be2fcd3a7e932';

export const telecomUserInfoURL = 'https://zenkigen.co.jp/telecom_userinfo';

export const LoginErrorType = {
  network: 'Network Error',
} as const;

export const ReportStatusName = {
  uncompleted: '未完了',
  in_progress: '進行中',
  completed: '完了',
  error: 'エラー',
} as const;

export const ApiErrorCode = {
  UNKNOWN_SERVER_ERROR: 0,
  ILLEGAL_ARGUMENT: 1, // parseのエラー
  INTERNAL_SERVER_ERROR: 2,
  ACCESS_DENIED: 3, // 権限エラー
  RESOURCE_NOT_FOUND: 4, // 要求したデータなどが存在しない時のエラー
  INVALID_SESSION: 5, // ログインが必要な機能全般
  NOT_AUTHENTICATED: 6,
  UNVERIFIED_ORGANIZATION: 7,
  DENY_IP_ADDRESS: 8,
  PASSWORD_EXPIRATION: 9, //パスワード更新期限満了
  TOKEN_EXPIRED: 10,
};

export const ConversationTendencyName = {
  listening: '聞く',
  dialogue: '対話',
  communication: '伝える',
} as const;

export const GuestSummaryListSortSetting = 'guestSummaryListSortSetting';

export const PartnerSummaryListSortSetting = 'partnerSummaryListSortSetting';

export const BehaviorAndStateAnalysisError = '今回のレポートで、解析エラーのため、こちらは表示されません。';
