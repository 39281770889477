import dayjs from 'dayjs';

import { createCompanyReportSettingsModel } from '@/models/company/companyReportSettings';
import { createDepartmentsModel, Department } from '@/models/company/departments';
import { createNetworkReportScopesModel, NetworkReportScope } from '@/models/company/networkReportScopes';
import { createPositionsModel, Position } from '@/models/company/positions';
import { createSectionsModel, Section } from '@/models/company/sections';
import { IndexCompaniesResponse, ShowAdminCompaniesResponse } from '@/types/typescript-axios';

export type AggregationPeriod = {
  id: number;
  startMonth: string;
  endMonth: string;
};

export type Auth0Organization = {
  code: string;
  enableMfa: boolean;
  connectionId: string;
  connectionName: string;
};

export type CompanySlackCredential = {
  id?: number;
  teamName?: string;
};

export type CompanyPageSettings = {
  report: number;
};

export type CompanyReportSetting = {
  isGuestReportEnabled: boolean;
  isGuestMovieEnabled: boolean;
  isGuestWordcloudEnabled: boolean;
  isGuestLiveTranscriptionEnabled: boolean;
  isOwnerMovieEnabled: boolean;
  isOwnerWordcloudEnabled: boolean;
  isOwnerLiveTranscriptionEnabled: boolean;
};

export type CompanyModel = {
  id: number;
  name: string;
  beforeOwnerSurveyId: number | null;
  afterOwnerSurveyId: number | null;
  beforeGuestSurveyId: number | null;
  afterGuestSurveyId: number | null;
  beforeFlatSurveyId: number | null;
  afterFlatSurveyId: number | null;
  isNineboxEnabled: boolean;
  isSurveyEnabled: boolean;
  isNotifySlackEnabledDefault?: boolean;
  isNotifyEmailEnabledDefault?: boolean;
  isSoraDataChannelEnabled: boolean;
  isSoraSimulcastEnabled: boolean;
  isShowInterviewAnalysisError: boolean;
  isPasswordResetEnabled: boolean;
  isCheckIpAddr?: boolean;
  isShowLiveChecker: boolean;
  isShowNotUsingOneOnOneEntityScreen: boolean;
  isUserResetPassword?: boolean;
  userMigrateStatus: 'completed' | 'in_progress';
  managementReportAggregationStartedAt?: string;
  managementReportAggregationMonthPeriod?: number;
  analysisModelVersion: number;
  aggregationMonthPeriods?: AggregationPeriod[];
  auth0Organization?: Auth0Organization;
  companySlackCredential?: CompanySlackCredential;
  companyPageSettings: CompanyPageSettings;
  /** @deprecated 新レポートでは使用していない */
  companyReportSettings?: CompanyReportSetting;
  sections: Section[];
  departments: Department[];
  positions: Position[];
  networkReportScopes: NetworkReportScope[];
};

export const dummyCompanyModel: CompanyModel = {
  id: 1,
  name: 'ZENKIGEN',
  companyPageSettings: {
    report: 2,
  },
  beforeOwnerSurveyId: null,
  afterOwnerSurveyId: null,
  beforeGuestSurveyId: null,
  afterGuestSurveyId: null,
  beforeFlatSurveyId: null,
  afterFlatSurveyId: null,
  isNineboxEnabled: true,
  isSurveyEnabled: true,
  isNotifySlackEnabledDefault: false,
  isNotifyEmailEnabledDefault: false,
  isSoraDataChannelEnabled: true,
  isSoraSimulcastEnabled: false,
  isShowInterviewAnalysisError: false,
  isPasswordResetEnabled: false,
  isCheckIpAddr: false,
  isUserResetPassword: true,
  userMigrateStatus: 'completed',
  managementReportAggregationStartedAt: '2022-02-28 15:00:00 UTC',
  managementReportAggregationMonthPeriod: 2,
  aggregationMonthPeriods: [
    {
      id: 0,
      startMonth: '202203',
      endMonth: '202204',
    },
    {
      id: 1,
      startMonth: '202205',
      endMonth: '202206',
    },
    {
      id: 2,
      startMonth: '202207',
      endMonth: '202208',
    },
    {
      id: 3,
      startMonth: '202209',
      endMonth: '202210',
    },
    {
      id: 4,
      startMonth: '202211',
      endMonth: '202212',
    },
    {
      id: 5,
      startMonth: '202301',
      endMonth: '202302',
    },
    {
      id: 6,
      startMonth: '202303',
      endMonth: '202304',
    },
  ],
  analysisModelVersion: 1,
  companyReportSettings: {
    isGuestReportEnabled: false,
    isGuestMovieEnabled: false,
    isGuestWordcloudEnabled: false,
    isGuestLiveTranscriptionEnabled: false,
    isOwnerMovieEnabled: true,
    isOwnerWordcloudEnabled: true,
    isOwnerLiveTranscriptionEnabled: false,
  },
  isShowLiveChecker: false,
  isShowNotUsingOneOnOneEntityScreen: false,
  sections: [],
  departments: [],
  positions: [],
  networkReportScopes: [],
};

export const createCompanyModel = (company: IndexCompaniesResponse | ShowAdminCompaniesResponse): CompanyModel => {
  let aggregationMonthPeriods: AggregationPeriod[] | undefined;

  if (
    company.company.management_report_aggregation_month_period &&
    company.company.management_report_aggregation_started_at
  ) {
    // NOTE: Safariでハイフンのある日付をDateに変換するとエラーになるため、ハイフンをスラッシュに置換する
    const start = dayjs(company.company.management_report_aggregation_started_at.replace(/-/g, '/')).startOf('month');
    const period = company.company.management_report_aggregation_month_period ?? 1;
    const diffMonth = dayjs().diff(start, 'month') + 1;
    const listSize = Math.trunc(diffMonth / period);
    const correctionListSize = diffMonth % period === 0 ? listSize : listSize + 1;

    aggregationMonthPeriods = Array.from(Array(correctionListSize).keys()).map(count => {
      return {
        id: count,
        startMonth: start.add(count * period, 'month').format('YYYYMM'),
        endMonth: start.add(count * period + period - 1, 'month').format('YYYYMM'),
      };
    });
  }

  return {
    id: company.company.id,
    name: company.company.name,
    beforeOwnerSurveyId:
      company.company.before_owner_survey_id && company.company.before_owner_survey_id > 0
        ? company.company.before_owner_survey_id
        : null,
    afterOwnerSurveyId:
      company.company.after_owner_survey_id && company.company.after_owner_survey_id > 0
        ? company.company.after_owner_survey_id
        : null,
    beforeGuestSurveyId:
      company.company.before_guest_survey_id && company.company.before_guest_survey_id > 0
        ? company.company.before_guest_survey_id
        : null,
    afterGuestSurveyId:
      company.company.after_guest_survey_id && company.company.after_guest_survey_id > 0
        ? company.company.after_guest_survey_id
        : null,
    beforeFlatSurveyId:
      company.company.before_flat_survey_id && company.company.before_flat_survey_id > 0
        ? company.company.before_flat_survey_id
        : null,
    afterFlatSurveyId:
      company.company.after_flat_survey_id && company.company.after_flat_survey_id > 0
        ? company.company.after_flat_survey_id
        : null,
    companyPageSettings: {
      report: company.company_page_settings.report,
    },
    isNineboxEnabled: company.company.is_ninebox_enabled,
    isSurveyEnabled: company.company.is_survey_enabled,
    isNotifySlackEnabledDefault: company.company.is_notify_slack_enabled_default,
    isNotifyEmailEnabledDefault: company.company.is_notify_email_enabled_default,
    isSoraDataChannelEnabled: company.company.is_sora_data_channel_enabled,
    isSoraSimulcastEnabled: company.company.is_sora_simulcast_enabled,
    isShowInterviewAnalysisError: company.company.is_show_interview_analysis_error,
    isPasswordResetEnabled: company.company.is_password_reset_enabled,
    isCheckIpAddr: company.company.is_check_ip_addr,
    isUserResetPassword: company.company.is_user_reset_password,
    isShowLiveChecker: company.company.is_show_live_checker,
    isShowNotUsingOneOnOneEntityScreen: company.company.is_show_not_using_one_on_one_entity_screen,
    userMigrateStatus: company.company.user_migrate_status,
    managementReportAggregationStartedAt: company.company.management_report_aggregation_started_at,
    managementReportAggregationMonthPeriod: company.company.management_report_aggregation_month_period,
    aggregationMonthPeriods,
    analysisModelVersion: company.company.analysis_model_version,
    companySlackCredential: company.company_slack_credential
      ? {
          id: company.company_slack_credential.id ?? undefined,
          teamName: company.company_slack_credential.team_name ?? undefined,
        }
      : undefined,
    companyReportSettings: company.company_report_setting
      ? createCompanyReportSettingsModel(company.company_report_setting)
      : undefined,
    sections: createSectionsModel(company.sections),
    departments: createDepartmentsModel(company.departments),
    positions: createPositionsModel(company.positions),
    networkReportScopes: createNetworkReportScopesModel(company.network_report_scopes),
  };
};
