import React from 'react';

interface Props {
  onError?: (error: unknown, info: React.ErrorInfo) => void;
  fallbackRender: (error: unknown) => React.ReactNode;
}

type State = { caught: unknown | null };

export class BaseErrorBoundary extends React.Component<React.PropsWithChildren<Props>, State> {
  state: State;

  constructor(props: Props) {
    super(props);
    this.state = {
      caught: null,
    };
  }

  static getDerivedStateFromError(caught: unknown) {
    return { caught };
  }

  componentDidCatch(error: unknown, info: React.ErrorInfo) {
    this.props.onError?.(error, info);
  }

  render() {
    const { caught } = this.state;

    if (caught) {
      return this.props.fallbackRender(caught);
    }

    return this.props.children;
  }
}
