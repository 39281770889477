import React, { useEffect, useState } from 'react';

import styled, { css } from 'styled-components';

import { ColorTokens, square } from '@/styles';

type Props = {
  size: 'small' | 'regular' | 'large';
  loading: boolean;
  position?: 'fixed' | 'absolute' | 'static';
  delayTime?: number;
  width?: string;
  height?: string;
};

export const Loading: React.FC<Props> = ({
  size,
  loading = false,
  delayTime = 0,
  position = 'fixed',
  height,
  width,
}) => {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      const loadingTimerId = window.setTimeout(() => {
        setShowLoading(true);
      }, delayTime);
      return () => {
        window.clearTimeout(loadingTimerId);
        setShowLoading(false);
      };
    }
    setShowLoading(false);
  }, [loading, delayTime]);

  if (!showLoading) {
    return null;
  }

  return (
    <StyledLoading size={size} position={position} height={height} width={width}>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle
          className="circle-base"
          cx="16"
          cy="16"
          r="14"
          stroke={ColorTokens.UiBorder01}
          strokeWidth="3"
          fill="none"
        />
        <circle className="circular-move" cx="16" cy="16" r="14" stroke="currentColor" strokeWidth="3" fill="none" />
      </svg>
    </StyledLoading>
  );
};

const StyledLoading = styled.div<Pick<Props, 'size' | 'position' | 'height' | 'width'>>`
  position: ${({ position }) => position};
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => (props.width != null ? props.width : '100%')};
  height: ${props => (props.height != null ? props.height : '100%')};

  & svg {
    ${({ size }) => {
      switch (size) {
        case 'small':
          return css`
            ${square(16)}
          `;
        case 'large':
          return css`
            ${square(64)}
            circle {
              stroke-width: 1.5;
            }
          `;
        default:
          return css`
            ${square(32)}
          `;
      }
    }}
    color: ${ColorTokens.Interactive01};

    & .circular-move {
      stroke-dasharray: 80px 200px;
      stroke-dashoffset: 0px;
      transform-origin: 50% 50%;
      animation: circular-move 1.4s ease-in-out infinite;
      @keyframes circular-move {
        0% {
          stroke-dasharray: 1px, 200px;
          stroke-dashoffset: 0px;
          transform: rotate(0deg);
        }
        50% {
          stroke-dasharray: 100px, 200px;
          stroke-dashoffset: -15px;
        }
        100% {
          stroke-dasharray: 100px, 200px;
          stroke-dashoffset: -125px;
          transform: rotate(360deg);
        }
      }
    }
  }
`;
