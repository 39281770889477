import { IndexCompaniesResponseNetworkReportScopes } from '@/types/typescript-axios';

export type NetworkReportScope = {
  positionUniqueKey: string;
  departmentUniqueKey: string;
  scopeDepartmentUniqueKey: string;
};

export const createNetworkReportScopesModel = (
  networkReportScopes: IndexCompaniesResponseNetworkReportScopes[],
): NetworkReportScope[] => {
  return networkReportScopes.map(networkReportScope => {
    return {
      positionUniqueKey: networkReportScope.position_unique_key,
      departmentUniqueKey: networkReportScope.department_unique_key,
      scopeDepartmentUniqueKey: networkReportScope.scope_department_unique_key,
    };
  });
};
