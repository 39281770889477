import { useEffect } from 'react';

import { useAtom } from 'jotai';
import { useRouter } from 'next/router';

import { selectedAdminCompanyIdAtom } from '@/atoms/selectedAdminCompanyId';

export const useSelectedAdminCompanyId = () => {
  const router = useRouter();

  const [selectedAdminCompanyId, setSelectedAdminCompanyId] = useAtom(selectedAdminCompanyIdAtom);

  useEffect(() => {
    const { companyId } = router.query;

    setSelectedAdminCompanyId(Number(companyId || undefined));
  }, [router, setSelectedAdminCompanyId]);

  return { selectedAdminCompanyId, setSelectedAdminCompanyId };
};
