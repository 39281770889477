import React, { useEffect, useState } from 'react';

import styled, { css } from 'styled-components';

import { BasicModal } from '@/components/shared/BasicModal';
import { Button } from '@/components/shared/Button';
import { ColorTokens, space } from '@/styles';

import { usePostAgreement } from './index.hook';

type Props = {
  setIsOpen: (flag: boolean) => void;
};

export const TermModal = ({ setIsOpen }: Props) => {
  const [isOpen] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const { mutate: postAgreement, isError } = usePostAgreement();

  const handleAgree = () => {
    postAgreement();
    setIsDisabled(true);
  };

  useEffect(() => {
    if (isError) {
      setIsDisabled(false);
    }
  }, [isError]);

  return (
    <BasicModal
      title="利用規約・プライバシーポリシー"
      centerTitle
      onModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      showTopSeparationLine={false}
      size={{ width: 'wide', height: 'fullscreen' }}
      maxHeight="600px"
      noPaddingBottom
      disableCloseButton
    >
      <StyledContainer>
        <StyledBasicModalContent>
          <p> 以下のすべての書面をお読みになり同意してください。 </p>
        </StyledBasicModalContent>
        <StyledIframeContainer>
          <StyledIframe title="tern of use" src="/web/2023-03-29/tou_privacy.html" />
        </StyledIframeContainer>
        <StyledFooter showBottomSeparationLine>
          <StyledButtonContainer oneButton>
            <Button onClick={handleAgree} styling="fill" widthStyle="fit" color="default" isDisabled={isDisabled}>
              同意する
            </Button>
          </StyledButtonContainer>
        </StyledFooter>
      </StyledContainer>
    </BasicModal>
  );
};

const StyledContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: ${space(2)};
`;

const StyledBasicModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: ${space(0)} ${space(2)};
`;

const StyledIframeContainer = styled.div`
  display: flex;
  align-items: center;
  height: 400px;
  width: 100%;
  padding: 8px;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
`;

const StyledFooter = styled.div<{ showBottomSeparationLine: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${space(6)} ${space(4)} ${space(4)};
  ${props =>
    props.showBottomSeparationLine
      ? css`
          border-top: 1px solid ${ColorTokens.UiBorder01};
        `
      : null}
`;

const StyledButtonContainer = styled.div<{ oneButton: boolean }>`
  display: flex;
  justify-content: flex-end;

  ${props =>
    props.oneButton
      ? css`
          flex-direction: column;
          align-items: center;
        `
      : css`
          justify-content: space-between;
          button:first-child {
            margin-right: ${space(8)};
          }
        `}
  background-color: ${ColorTokens.UiBackground01};
`;
